.page-print .logo{display:block;float:none;text-align:left}@media print{*{-webkit-filter:none !important;background:transparent !important;color:#000 !important;filter:none !important;text-shadow:none !important}a,a:visited{text-decoration:underline !important}pre,blockquote{border:1px solid #999;page-break-inside:avoid}thead{display:table-header-group}.table-wrapper table{width:100%;table-layout:fixed}.table-wrapper table td{width:auto}.table-wrapper table>tfoot>tr:first-child{border-top:1px solid #999}.box,tr,img{page-break-inside:avoid}img{max-width:100% !important}@page{margin:1cm}.block-content,p,h2,h3{orphans:3;widows:3}.block-content{page-break-before:avoid}.block-title,h2,h3{page-break-after:avoid}.nav-toggle{display:none !important}.sidebar,.nav-sections,.header.content>*[class],.panel.wrapper>*[class],.footer.content>*[class]{display:none}.logo,.footer .copyright{display:block !important;margin:10px 0}.order-details-items .order-items .order-gift-message:not(.expanded-content){height:auto;visibility:visible}.column.main{float:none !important;width:100% !important}.breadcrumbs{margin:0 auto;text-align:right}.footer.content{padding:0}.hidden-print{display:none !important}}

header,
footer,
.page-footer,
.copyright-section,
#form-validate > div.cart.main.actions,
#block-shipping,
#shopping-cart-table > tbody > tr.item-actions,
#maincontent > div.columns > div > div.cart-container > div.cart-summary > ul,
#maincontent > div.columns > div > div.cart-container > div.cart-discount {
    display: none !important;
}

.cart.table-wrapper .item .col.item {
    padding: 0px !important;
}

.cart-container {
    display: flex;
    flex-wrap: wrap;
}

.cart-container .cart-summary {
    width: 100%;
    order: 2;
}

.cart-container .form-cart {
    width: 100%;
    flex-wrap: wrap;
}

.cart.table-wrapper .cart thead th.col.price,
.cart.table-wrapper .cart thead th.col.qty,
.cart.table-wrapper .cart thead th.col.subtotal {
    width:120px;
}